@import "../../../../styles/core.less";

.master-header.ant-layout-header {
  min-height: 64px;
  height: 100%;
  background: white;
  border-bottom: 1px solid @border-color-base;
  display: flex;
  justify-content: center;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: @application-max-width;
  flex: 1;
  color: white;

  .left-menu-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: white;

    .logo {
      width: 120px;
      margin: @padding-sm;
    }

    .header-title {
      font-family: "Permanent Marker", @font-family;
      font-size: 22px;
      margin-left: 8px;
      margin-right: @padding-md;
      color: white;
    }
  }

  .right-menu-content {
    margin: 0px @padding-md;
    color: white;
  }
}

@primary-color: #1DA57A;