@import "../../styles/core.less";

.main-layout {
  display: flex;
  justify-content: center;
  background-color: @color-catskill-white;

  .main-content {
    max-width: @application-max-width;
    flex: 1;
  }
}

@primary-color: #1DA57A;