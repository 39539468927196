@font-face {
  font-family: "sstArabicBold";
  src: url("../src/assets/fonts/sstarabicbold.ttf");
}
@font-face {
  font-family: "sstArabicLight";
  src: url("../src/assets/fonts/sstarabiclight.ttf");
}

body {
  margin: 0;
  font-family: "sstArabicBold", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
